import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import ContactIcons from '../components/contactIcons';
import Grid from '@material-ui/core/Grid';
import ReturnArrow from '../images/return-arrow.svg';
import { Helmet } from 'react-helmet'

const Contact = () => (
      <Layout>
        <Helmet>
          <title>David Hildering [ Contact me ] -Freelance Front-end Developer</title>
          <meta name="description" content="Hi, My name is David Hildering. I am a freelance front-end developer from Utrecht."/>
        </Helmet>
        <Grid container
              className="link-list"
        >
          <Grid item xs={4}>
            <div>
              <Link to="/"><img src={ ReturnArrow } className="return-arrow" width='30px' alt="Return Logo" /></Link>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div>
              <Link to="/about/">About</Link>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div>
              <Link to="/projects/">Projects</Link>
            </div>
          </Grid>
        </Grid>
            <Grid container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={8}>
              <div className='app-intro'>
                <Grid item xs={12}>
                  <h2>Contact me</h2>
                </Grid>
                <Grid container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      className="about-content">
                  <Grid item md={6}>
                    <p>I am a freelance Front-end Developer located in Utrecht.</p>
                    <p>If you would like to discuss an opportunity with me,
                    please send me an email: <a className="link" href='mailto:mail@davidhildering.nl'> mail@davidhildering.nl</a> </p>
                      <p>or PM me on LinkedIn and I'll get back to you as soon as possible.</p>
                  </Grid>
                  <Grid item md={6}>
                    <ContactIcons />
                  </Grid>
                </Grid>
              </div>
            </Grid>
      </Layout>
)

export default Contact;
