import React from 'react'
import Grid from '@material-ui/core/Grid';
import MailIcon from '../images/contact/mail-logo.svg';
import LinkedInIcon from '../images/contact/linkedin-logo.svg';




const ContactIcons = () => (
  <div style={{
    padding: '0 15px 15px 15px'
  }}>
    <Grid container
          direction="row"
          justify="center"
          alignItems="center"
    >

      <Grid item xs md={6}>
        <a href='https://linkedin.com/in/david-hildering-developer' target="_blank" rel="noopener noreferrer" title="David Hildering LinkedIn Profile"><img src={ LinkedInIcon } className="skill-logo project-logo"  alt="contact linkedIn logo"/></a>
      </Grid>
      <Grid item xs md={6}>
        <a href='mailto:mail@davidhildering.nl' title='mail@davidhildering.nl'><img src={ MailIcon } className="skill-logo project-logo"  alt="Contact Email logo" /></a>
      </Grid>
    </Grid>
  </div>
)


export default ContactIcons;
